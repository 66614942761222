<template>
  <main class="md:ml-12 mr-4 md:mr-0">
    <div class="wrapper" v-if="invoices && customers">
      <div class="flex-col mt-12 mb-8">
        <h2>Invoices</h2>
        <h3 class="font-bold mt-4 text-2xl">Invoices</h3>
      </div>
      <div class="bg-white py-2 rounded-lg big-shadow">
        <div class="mt-8 mb-5 mx-8 md:justify-between grid gap-2 md:flex flex-row">
          <div
            class="flex flex-row bg-white items-center rounded big-shadow justify-between px-2 md:w-5/12"
          >
            <span class="text-xs font-light text-primaryGray w-full">
              <input
                class="w-full py-2 px-4 outline-none"
                type="text"
                name=""
                id=""
                placeholder="Search for invoice via customer..."
                @input="filterBySearch"
              />
              <!-- v-model.trim="searchedProduct" -->
            </span>
            <img src="../../assets/svg/Search_icon.svg" />
          </div>
          <div class="">
          </div>
          <trac-dropdown
            class="md:w-40"
            :choice="true"
            title="Invoice Status"
            :menuItems="invoiceStatus"
            :selectedData="checkedFilterItemsForInvoieStatus"
            @add-to-filter="selectInvoiceStatus"
            checkBoxItems
          >
          </trac-dropdown>
          <trac-dropdown
            class="md:w-40"
            :choice="true"
            title="Order Status"
            :menuItems="orderStatus"
            :selectedData="checkedFilterItemsForOrderStatus"
            @add-to-filter="selectOrderStatus"
            checkBoxItems
          >
          </trac-dropdown>
          <div class="">
            <!-- <trac-dropdown-exteneded
              :neededProperty="'name'"
              :selector="'All Order Status'"
              :options="orderStatus"
              :inputDate="''"
              class=""
              @optionSelected="selectOrderStatus"
            ></trac-dropdown-exteneded> -->
            
          </div>
          <div v-if="userPermissions.createInvoice">
            <trac-button
              class="uppercase w-full"
              @button-clicked="gotoCreateInvoicePage"
              >Create Invoice
            </trac-button>
          </div>
        </div>
        <div class="mx-8 mb-8 border rounded-lg overflow-scroll">
          <table class="w-full table-auto">
            <thead class="bg-blue-100">
              <tr class="h-10">
                <th class="text-left pl-8 text-xs font-semibold whitespace-no-wrap px-4">INV. NO</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap px-4">INVOICE STATUS</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap px-4">ORDER STATUS</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap px-4">DATE</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap px-4">CUSTOMER</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap px-4">INVOICE AMOUNT</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap px-4">AMOUNT DUE</th>
              </tr>
            </thead>
            <tbody v-if="filteredInvoices.length > 0">
              <tr
                :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                v-for="(invoice, index) in filteredInvoices"
                class="h-10 cursor-pointer hover:bg-gray-200"
                :key="index"
                @click="gotoInvoiceDetails(invoice)"
              >
                <td class="whitespace-no-wrap px-4 text-xs pl-8">
                  {{ invoice.sn | serialNumberFormat }}
                </td>
                <td class="whitespace-no-wrap px-4 text-sm capitalize">
                  <div class="flex flex-row text-xs">
                    <img class="mr-4" src="../../assets/svg/Sent.svg" />
                    {{ invoice.invoice_status }}
                  </div>
                </td>
                <td class="whitespace-no-wrap px-4 text-xs">
                  <div class="flex flex-row capitalize">
                    <img class="mr-4" src="../../assets/svg/Fulfilled.svg" />
                    {{ invoice.order_status }}
                  </div>
                </td>
                <td class="whitespace-no-wrap px-4 text-xs capitalize">
                  {{ invoice.date }}
                </td>
                <td class="whitespace-no-wrap px-4 text-xs capitalize">
                  {{
                    invoice.customer ? invoice.customer.first_name : 'No client' +
                    " " +
                    (invoice.customer ? invoice.customer.last_name : '')
                  }}
                </td>
                <td class="whitespace-no-wrap px-4 text-xs">
                  {{ invoice.invoice_amount | formatPrice(false) }}
                </td>
                <td class="whitespace-no-wrap px-4 text-xs text-secondaryRed">
                  {{ invoice.due | formatPrice(false) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      {{
                        searchedData.length > 0
                          ? invoices.length > 0
                            ? "No match found."
                            : "No invoice added yet."
                          : "No invoice added yet."
                      }}
                    </div>
                  </trac-center-data>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="w-full h-screen" v-else>
      <trac-loading />
    </div>
  </main>
</template>

<script>
import moment from "moment";
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";
import { permissionsModules } from "./../settings/permission";

export default {
  name: "Invoices",
  changed: false,
  data() {
    return {
      moment,
      userPermissions: null,
      permissionsModules,
      searchedData: "",
      sortedData: new Date().toLocaleTimeString(),
      selectedOrderStatus: [],
      selectedInvoiceStatus: [],
      orderStatus: [
        { _id: "all", name: "All Order Status", value: "all" },
        { _id: "unfulfilled", name: "Unfulfilled", value: "unfulfilled" },
        { _id: "fulfilled", name: "Order Fulfilled", value: "fulfilled" },
        { _id: "partially", name: "Partly Fulfilled", value: "partially" },
      ],
      invoiceStatus: [
        { _id: "all", name: "all", value: "all" },
        { _id: "sent", name: "Sent", value: "sent" },
        { _id: "closed", name: "Closed", value: "closed" },
        { _id: "paid", name: "Paid", value: "paid" },
        { _id: "unsent", name: "Unsent", value: "unsent" },
      ],
      invoices: null,
      filteredInvoices: null,
      customers: null,
    };
  },
  async created() {
    this.userPermissions =
        permissionsModules[1]["parent"]["permissionsForUser"];

    this.selectedInvoiceStatus = this.invoiceStatus.map((item) => item.value);
    this.selectedOrderStatus = this.orderStatus.map((item) => item.value);

    await this.fetchCustomers();
    await this.fetchInvoices();
  },
  mounted() {
    DELETE_LOCAL_DB_DATA("invoice-details");
    DELETE_LOCAL_DB_DATA("invoice-details-for-profile-update");
  },
  computed: {
    checkedFilterItemsForInvoieStatus() {
      return this.selectedInvoiceStatus;
    },
    checkedFilterItemsForOrderStatus() {
      return this.selectedOrderStatus;
    },
    sortInvoiceByDate() {
      return this.changed ? new Date().toLocaleDateString() : "Select Date";
    },
  },
  methods: {
    // selectOrderStatus(option) {
    //   this.filteredInvoices = this.invoices;
    //   if (option.value === "") {
    //     this.filteredInvoices = this.invoices;
    //   } else {
    //     this.filteredInvoices = this.invoices.filter(
    //       (invoice) =>
    //         invoice.order_status.toLowerCase() === option.value.toLowerCase()
    //     );
    //   }
    // },
    selectOrderStatus(e) {
      if (e.checked) {
        if (e.value._id === "all") {
          this.selectedOrderStatus = this.orderStatus.map(
            (item) => item.value
          );
        } else {
          this.selectedOrderStatus.push(e.value.value);
          if (this.selectedOrderStatus.length === this.orderStatus.length - 1) {
            this.selectedOrderStatus.unshift('all');
          }
        }
      } else {
        if (e.value._id === "all") {
          this.selectedOrderStatus.splice(
            0,
            this.selectedOrderStatus.length
          );
        } else {
          this.selectedOrderStatus = this.selectedOrderStatus
            .filter((stat) => stat !== e.value.value)
            .map((stat) => stat);

            if (this.selectedOrderStatus.length === this.orderStatus.length - 1) {
            this.selectedOrderStatus.shift();
          }
        }
      }

      this.filteredInvoices = this.invoices.filter(inv => this.selectedOrderStatus.includes(inv.order_status.toLowerCase()));
    },
    selectInvoiceStatus(e) {
      if (e.checked) {
        if (e.value._id === "all") {
          this.selectedInvoiceStatus = this.invoiceStatus.map(
            (item) => item.value
          );
        } else {
          this.selectedInvoiceStatus.push(e.value.value);
          if (this.selectedInvoiceStatus.length === this.invoiceStatus.length - 1) {
            this.selectedInvoiceStatus.unshift('all');
          }
        }
      } else {
        if (e.value._id === "all") {
          this.selectedInvoiceStatus.splice(
            0,
            this.selectedInvoiceStatus.length
          );
        } else {
          this.selectedInvoiceStatus = this.selectedInvoiceStatus
            .filter((stat) => stat !== e.value.value)
            .map((stat) => stat);
        }

         if (this.selectedInvoiceStatus.length === this.invoiceStatus.length - 1) {
            this.selectedInvoiceStatus.shift();
          }
      }

      this.filteredInvoices = this.invoices.filter(inv => this.selectedInvoiceStatus.includes(inv.invoice_status.toLowerCase()));
    },
    filterBySearch(e) {
      const value = e.target.value;
      this.searchedData = value;
      this.filteredInvoices = this.invoices.filter(
        (invoice) =>
          invoice.customer.first_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          invoice.customer.last_name.toLowerCase().includes(value.toLowerCase())
      );
    },
    gotoCreateInvoicePage() {
      this.$router.push({ name: "AddInvoice" });
    },
    gotoInvoiceDetails(invoice) {
      if (!invoice.customer) {
         eventBus.$emit("trac-alert", { message: 'This invoice has no customer.' });
         return;
      }
      SAVE_LOCAL_DB_DATA("invoice-details", invoice);
      this.$router.push({ name: "InvoiceDetails" });
    },
    async fetchInvoices() {
      // Fetch all invoices
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      const InvoicesRes = this.$store.getters["GET_ALL_INVOICES"];

      if (InvoicesRes.status) {
        if (InvoicesRes.data) {
          this.invoices = InvoicesRes.data.invoice
            ? InvoicesRes.data.invoice
                .map((inv) => {
                  return {
                    ...inv,
                    _id: inv._id,
                    sn: inv.sn,
                    invoice_status: inv.invoice_status,
                    order_status: inv.order_status,
                    date: moment(inv.created_at).format("ddd MMM DD YYYY"),
                    customer: this.customers.find(
                      (cus) => {
                        return cus._id === inv.client;
                      }
                    ),
                    invoice_amount: inv.balance.total.amount
                      ? inv.balance.total.amount
                      : "0",
                    due: inv.balance.outstanding.amount
                      ? inv.balance.outstanding.amount
                      : "0",
                  };
                })
                .reverse()
            : [];

          this.filteredInvoices = this.invoices;
        }
      } else {
        // alert(InvoicesRes.message);
        eventBus.$emit("trac-alert", { message: InvoicesRes.message });
      }
    },
    async fetchCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const customersRes = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (customersRes.status) {
        const customersData = customersRes.data
          ? customersRes.data.items || []
          : [];
        this.customers = customersData;
      } else {
        // alert(customersRes.message);
        eventBus.$emit("trac-alert", { message: customersRes.message });
      }
    },
  },
};
</script>

<style scoped></style>
